import _ from 'lodash';

// Styles
// import './style.css';

// GSAP
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { GSDevTools } from "gsap/GSDevTools";
import { CustomEase } from "gsap/CustomEase";

// SWUP
import Swup from 'swup';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupDebugPlugin from '@swup/debug-plugin';
import SwupGtmPlugin from '@swup/gtm-plugin';
//import SwupGtagPlugin from 'swup-gtag-plugin';
import SwupGaPlugin from '@swup/ga-plugin';
import SwupRouteNamePlugin from '@swup/route-name-plugin';

// jQuery
import $ from "jquery";



/**
 * Register GSAP Plugins
 */
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(GSDevTools);



/**
 * SWUP
 *
 * New Swup object
 */
const swup = new Swup({
    linkSelector: 'a',
    animateHistoryBrowsing: true,
    plugins: [
        new SwupBodyClassPlugin(),
        new SwupDebugPlugin(),
        new SwupGaPlugin({ gaMeasurementId: 'G-1J2NDQFRTN' }),
        new SwupGtmPlugin(),
        new SwupRouteNamePlugin({paths: true}),
    ]
});



/**
 * SWUP : JS Reloading
 *
 * Reloads javascript based on the querySelector
 */
loadJS();

function loadJS() {

    if (document.querySelector('#interior')) {

        /**
         * Init
         *
         * Add classes to the first child of buttons and tabs
         */
        $('.tab-btn:first').addClass("active");
        $('.tab-container div:first').addClass('active');
        $(".tab-item video").trigger('pause');



        /**
         * Tabs Mouse Events
         */
        var tabBtn = $('.tab-btn');

        tabBtn.on('click', function (e) {

            e.preventDefault();
            swapTabGlobal($(this));
            return false;
        });



        /**
         * Tabs
         *
         * Simple tab system.
         */
        function swapTabGlobal(tab) {

            // Hide/Display Slides
            $(".tab-btn").removeClass("active");
            tab.addClass("active");

            // Grab Slide ID
            var tabNumber = tab.attr('id');

            // Remove active from item
            $(".tab-item").removeClass("active");

            // Add active to tabs
            $(".--tab-" + tabNumber).addClass('active');

            // Play/Pause Video
            $(".tab-item video").trigger('pause');
            $(".tab-item.active video").trigger('play');
        };

    }

    if (document.querySelector('#home, #residential')) {

        function animPulse() {
            var tlPulse = gsap.timeline();

            tlPulse.fromTo('.--pulse-1 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "one")
                .fromTo('.--pulse-1 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "one+=0.25")
                .fromTo('.--pulse-2 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "two")
                .fromTo('.--pulse-2 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "two+=0.25")
                .fromTo('.--pulse-3 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "three")
                .fromTo('.--pulse-3 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "three+=0.25")
                .fromTo('.--pulse-4 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "four")
                .fromTo('.--pulse-4 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "four+=0.25")
                .fromTo('.--pulse-5 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "five")
                .fromTo('.--pulse-5 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "five+=0.25")
                .fromTo('.--pulse-6 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "six")
                .fromTo('.--pulse-6 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "six+=0.25")
                .fromTo('.--pulse-7 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "seven")
                 .fromTo('.--pulse-7 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 5, immediateRender: false, ease: "expo.out" }, "seven+=0.25");

            return tlPulse;
        }

        var pulse = gsap.timeline({ repeat: -1 });
        pulse.add(animPulse());

    }
    if (document.querySelector('#healthcare, #educational, #sports-complexes, #uponor-offices, #uponor-offices-manufacturing')) {

        function animPulse() {
            var tlPulse = gsap.timeline();

            tlPulse.fromTo('.--pulse-1 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "one")
                .fromTo('.--pulse-1 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "one+=0.25")
                .fromTo('.--pulse-2 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "two")
                .fromTo('.--pulse-2 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "two+=0.25")
                .fromTo('.--pulse-3 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "three")
                .fromTo('.--pulse-3 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "three+=0.25")
                .fromTo('.--pulse-4 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "four")
                .fromTo('.--pulse-4 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "four+=0.25");

            return tlPulse;
        }

        var pulse = gsap.timeline({ repeat: -1 });
        pulse.add(animPulse());

    }
    if (document.querySelector('#residential-bathroom, #hospitality, #uponor-offices-distribution')) {

        function animPulse() {
            var tlPulse = gsap.timeline();

            tlPulse.fromTo('.--pulse-1 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "one")
                .fromTo('.--pulse-1 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "one+=0.25")
                .fromTo('.--pulse-2 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "two")
                .fromTo('.--pulse-2 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "two+=0.25")
                .fromTo('.--pulse-3 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "three")
                .fromTo('.--pulse-3 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "three+=0.25");

            return tlPulse;
        }

        var pulse = gsap.timeline({ repeat: -1 });
        pulse.add(animPulse());

    }



    /**
     * Animation Pulse
     * 
     * 
     */
    if (document.querySelector('#residential-living-room, #residential-kitchen, #residential-mech-room')) {

        function animPulse() {
            var tlPulse = gsap.timeline();

            tlPulse.fromTo('.--pulse-1 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "one")
                .fromTo('.--pulse-1 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "one+=0.25")
                .fromTo('.--pulse-2 .--ring-1', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "two")
                .fromTo('.--pulse-2 .--ring-2', { opacity: 1 }, { duration: 3, opacity: 0, scale: 4, immediateRender: false, ease: "expo.out" }, "two+=0.25");

            return tlPulse;
        }

        var pulse = gsap.timeline({ repeat: -1 });
        pulse.add(animPulse());

    }



    /**
     * Dash Animations
     * 
     * Pipe animations for residential living room,
     * bathroom, kitchen, and mechanical room.
     */
    if (document.querySelector('#residential-living-room')) {

        function radientHeatDash() {
            var radientHeatDash = gsap.timeline();

            radientHeatDash.to("#radient-heat-living-room", { strokeDashoffset: -118, ease: "none", duration: 3 });

            return radientHeatDash;
        }

        var radientHeatDashTl = gsap.timeline({ repeat: -1 });
        radientHeatDashTl.add(radientHeatDash());
    }

    if (document.querySelector('#radient-heat-bathroom-floor')) {

        function radientHeatDash() {
            var radientHeatDash = gsap.timeline();

            radientHeatDash.to("#radient-heat-bathroom-floor", { strokeDashoffset: -118, ease: "none", duration: 3 });

            return radientHeatDash;
        }

        var radientHeatDashTl = gsap.timeline({ repeat: -1 });
        radientHeatDashTl.add(radientHeatDash());
    }

    if (document.querySelector('#radient-heat-bathroom-wall')) {

        function radienHeatBathroomWall() {
            var radienHeatBathroomWallTl = gsap.timeline();

            radienHeatBathroomWallTl.to("#radient-heat-bathroom-wall", { strokeDashoffset: -118, ease: "none", duration: 3 });

            return radienHeatBathroomWallTl;
        }

        var radienHeatBathroomWallTlM = gsap.timeline({ repeat: -1 });
        radienHeatBathroomWallTlM.add(radienHeatBathroomWall());
    }

    if (document.querySelector('#radient-heat-kitchen')) {

        function radienHeatKitchen() {
            var radienHeatKitchenTl = gsap.timeline();

            radienHeatKitchenTl.to("#radient-heat-kitchen", { strokeDashoffset: -118, ease: "none", duration: 3 });

            return radienHeatKitchenTl;
        }

        var radienHeatKitchenTlM = gsap.timeline({ repeat: -1 });
        radienHeatKitchenTlM.add(radienHeatKitchen());
    }



    /**
     * Storm Animations
     * 
     * This function could be adapted to use snow,
     * rain, or other kinds of weather animations.
     * 
     * Original Author: DIACO : twitter.com/Diaco_ml  ||  codepen.io/MAW
     */
    function stormSnow() {
        var total = 50;
        var warp = document.getElementById("storm-snow");
        var i = 0;

        // Create snow flakes
        for (i = 0; i < total; i++) {
            var Div = document.createElement('div');
            gsap.set(Div, { attr: { class: 'snowflake' }, x: R(0, 200), y: R(0, 0), z: R(0, 0) });
            warp.appendChild(Div);
            animm(Div);
        }

        // Animate the snow flakes
        function animm(elm) {
            gsap.to(elm, { y: 250, ease: "none", repeat: -1, delay: -15, duration: R(3, 6.5) });
            gsap.to(elm, { x: '+=45', repeat: -1, yoyo: true, ease: "sine.inOut", duration: R(5, 7) });
        };

        // Random durations for slow and fast snow flakes
        function R(min, max) { return min + Math.random() * (max - min) };
    }

    function clouds() {
        var cloudstl = gsap.timeline();

        cloudstl.from(".cloud-1", { duration: 2, opacity: 0, y: -16, scale: .5, ease: "power3.inOut" }, "in")
            .from(".cloud-2", { duration: 2, opacity: 0, x: -16, scale: .5, ease: "power3.inOut" }, "in")
            .from(".cloud-3", { duration: 2, opacity: 0, x: -16, y: -16, scale: .5, ease: "power3.inOut" }, "in")
            .from(".cloud-4", { duration: 2, opacity: 0, y: 16, scale: .5, ease: "power3.inOut" }, "in")
            .from(".cloud-5", { duration: 2, opacity: 0, x: 8, y: -16, scale: .5, ease: "power3.inOut" }, "in")
            .from(".cloud-6", { duration: 2, opacity: 0, x: -1, scale: .5, ease: "power3.inOut" }, "in")
            .to(".cloud-1", { duration: 3, y: 10, x: 4, yoyo: true, ease: "power1.inOut", repeat: -1 }, "clouds")
            .to(".cloud-2", { duration: 3, y: 4, x: 8, yoyo: true, ease: "power1.inOut", delay: .25, repeat: -1 }, "clouds")
            .to(".cloud-3", { duration: 3, y: 8, x: 8, yoyo: true, ease: "power1.inOut", delay: .5, repeat: -1 }, "clouds")
            .to(".cloud-4", { duration: 3, y: 12, x: 8, yoyo: true, ease: "power1.inOut", delay: .08, repeat: -1 }, "clouds")
            .to(".cloud-5", { duration: 3, y: 6, x: 6, yoyo: true, ease: "power1.inOut", delay: 1, repeat: -1 }, "clouds")
            .to(".cloud-6", { duration: 3, y: 8, x: -8, yoyo: true, ease: "power1.inOut", delay: .15, repeat: -1 }, "clouds")
            .to("#storm-snow", { duration: 1.5, opacity: 1 }, "clouds-=.25");

        return cloudstl;
    }

    var cloudstltlm = gsap.timeline({ repeat: -1 });

    if (document.querySelector('#educational')) {
        stormSnow();
        cloudstltlm.add(clouds());
    }

    if (document.querySelector('#healthcare')) {
        stormSnow();
        cloudstltlm.add(clouds());
    }

    if (document.querySelector('#residential')) {
        stormSnow();
        cloudstltlm.add(clouds());
    }

    if (document.querySelector('#sports-complexes')) {
        stormSnow();
        cloudstltlm.add(clouds());
    }



    /**
     * Traffic Offices
     * 
     * 
     */
    if (document.querySelector('#uponor-offices')) {

        // Road 1
        function car1() {
            var tl = gsap.timeline({id: 'car1'});

            tl.to("#car1", { duration: .5, opacity: 1, filter: "blur(0px)" }, "spawn")
                .to("#car1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: '#path-road-1',
                        align: "#path-road-1",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#car1", { duration: 1.3, opacity: .6, ease: "sine.out", filter: "grayscale(1) blur(5px)" }, "despawn-=5.6")
                .to("#car1", { duration: .5, opacity: 0 }, "despawn-=1");
            return tl;
        }

        function semi2() {
            var tl = gsap.timeline({id: 'semi2'});
            tl.to("#semi2", { duration: .5, opacity: 1, filter: "blur(0px)" }, "spawn")
                .to("#semi2", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: '#path-road-1',
                        align: "#path-road-1",
                        alignOrigin: [0.7, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#semi2", { duration: 1.3, opacity: .6, ease: "sine.out", filter: "grayscale(1) blur(8px)" }, "despawn-=5.6")
                .to("#semi2", { duration: .5, opacity: 0 }, "despawn-=1");
            return tl;
        }


        // Road 2
        function truck1() {
            var tl = gsap.timeline({id: 'truck1'});
            tl.to("#truck1", { duration: .5, opacity: .6, filter: "grayscale(1) blur(8px)" }, "spawn")
                // .to("#truck1", { duration: 4, filter: "blur(0)" }, "spawn+=5")
                .to("#truck1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: '#path-road-2',
                        align: "#path-road-2",
                        alignOrigin: [0.65, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#truck1", { duration: 1.43, opacity: 1, ease: "sine.out", filter: "grayscale(0) blur(0px)" }, "despawn-=5.91")
                .to("#truck1", { duration: .5, opacity: 0, filter: "blur(5px)" }, "despawn-=.5");
            return tl;
        }

        function car2() {
            var tl = gsap.timeline({id: 'car2'});
            tl.to("#car2", { duration: .5, opacity: .6, filter: "grayscale(1) blur(8px)" }, "spawn")
                .to("#car2", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: '#path-road-2',
                        align: "#path-road-2",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "power1.Out"
                }, "spawn")
                .to("#car2", { duration: 1.03, opacity: 1, ease: "sine.out", filter: "grayscale(0) blur(0px)" }, "despawn-=7.69")
                .to("#car2", { duration: .5, opacity: 0, filter: "blur(5px)" }, "despawn-=.5");
            return tl;
        }


        // Traffic Jam
        function car3() {
            var tl = gsap.timeline({id: 'car3'});

            tl.to("#car3", { duration: 2.5, opacity: 1 }, "car3")
                .to("#car3", {
                    duration: 7,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: '#path-traffic-jam',
                        align: "#path-traffic-jam",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: .90
                    },
                    ease: "power1.out",
                }, "car3");
            return tl;
        }

        function car4() {
            var tl = gsap.timeline({id: 'car4'});
            tl.to("#car4", { duration: 2.5, opacity: 1 }, "car4")
                .to("#car4", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: '#path-traffic-jam',
                        align: "#path-traffic-jam",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: .70
                    },
                    ease: "power3.out",
                }, "car4");
            return tl;
        }

        function van1() {
            var tl = gsap.timeline({id: 'van1'});
            tl.to("#van1", { duration: 2.5, opacity: 1 }, "van1")
                .to("#van1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: '#path-traffic-jam',
                        align: "#path-traffic-jam",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: .50
                    },
                    ease: "none",
                }, "van1");
            return tl;
        }


        // Truck Loading Dock
        function semi1() {
            var tl = gsap.timeline({id: 'semi1'});
            tl.to("#semi1", { duration: 1, opacity: 1 }, "semi1")
                .to("#semi1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#path-truck-reverse",
                        align: "#path-truck-reverse",
                        alignOrigin: [0.08, 0.5],
                        start: .5,
                        end: 1
                    }
                }, "semi1");
            return tl;
        }

        // Master Timeline For Repeating Traffic
        var tlMaster = gsap.timeline({ repeat: -1, repeatDelay: 1 });
        tlMaster
            .add(car1(), 'wave1')
            .add(car2(), 'wave1')
            .add(truck1(), '<+=2')
            .add(semi2(), '<+=3');
            
            //GSDevTools.create({animation: tlMaster});

        // Master Timeline For One Time Traffic
        var tlMaster2 = gsap.timeline();
        tlMaster2
            .add(car3(), 'wave2')
            .add(car4(), '<+=2')
            .add(van1(), '<+=1')
            .add(semi1(), 'wave2');

        // Unload animation on page switch
        swup.hooks.before('content:replace', () => unload(tlMaster, tlMaster2));
    }



    /**
     * Traffic Hospitality
     * 
     * 
     */
    if (document.querySelector('#hospitality')) {

        // Road 1
        function pickup2() {
            var tl = gsap.timeline({id: 'pickup2'});
            tl.to("#pickup2", { duration: .5, opacity: 1, filter: "blur(0px)" }, "spawn")
                .to("#pickup2", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road1",
                        align: "#traffic-road1",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#pickup2", { duration: .5, opacity: .75, filter: "grayscale(.5) blur(5px)" }, "despawn-=2.5")
                .to("#pickup2", { duration: 1, opacity: 0 }, "despawn-=1");
            return tl;
        }

        // Road 2
        function pickup1() {
            var tl = gsap.timeline({id: 'pickup1'});
            tl.to("#pickup1", { duration: .5, opacity: .6, filter: "grayscale(1) blur(8px)" }, "spawn")
                .to("#pickup1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: '#traffic-road2',
                        align: "#traffic-road2",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: .85
                    },
                    ease: "power1.Out"
                }, "spawn")
                .to("#pickup1", { duration: .54, opacity: 1, ease: "sine.out", filter: "grayscale(0) blur(0px)" }, "despawn-=9.2");
            return tl;
        }

        function semi1() {
            var tl = gsap.timeline({id: 'semi1'});
            tl.to("#semi1", { duration: .5, opacity: .6, filter: "grayscale(1) blur(8px)" }, "spawn")
                .to("#semi1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road2",
                        align: "#traffic-road2",
                        alignOrigin: [0.08, 0.5],
                        start: 0,
                        end: .90
                    },
                    ease: "power1.Out"
                }, "spawn")
                .to("#semi1", { duration: .54, opacity: 1, ease: "sine.out", filter: "grayscale(0) blur(0px)" }, "despawn-=9.2");
            return tl;
        }

        // Road 3
        function car2() {
            var tl = gsap.timeline({id: 'car2'});
            tl.to("#car2", { duration: 3.5, opacity: 1 }, "spawn")
                .to("#car2", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road3",
                        align: "#traffic-road3",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#car2", { duration: .5, opacity: .75 }, "despawn-=6")
                .to("#car2", { duration: 1, opacity: 0 }, "despawn-=1");
            return tl;
        }

        // Master Timeline For Repeating Traffic
        var tlMaster = gsap.timeline({ repeat: -1, repeatDelay: 1 });
        tlMaster
            .add(car2(), 'wave1');

        // Master Timeline For One Time Traffic
        var tlMaster2 = gsap.timeline();
        tlMaster2
            .add(semi1(), 'wave1')
            .add(pickup1(), '<+=2')
            .add(pickup2(), '<+=2');
        
        //GSDevTools.create({animation: tlMaster2});
        
        // Unload animation on page switch
        swup.hooks.before('content:replace', () => unload(tlMaster, tlMaster2));

    }



    /**
     * Traffic Educational
     * 
     * 
     */
    if (document.querySelector('#educational')) {

        // Road 1
        function bus1() {
            var tl = gsap.timeline();
            tl.to("#bus1", { duration: .5, opacity: .5 }, "bus1")
                .to("#bus1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road1",
                        align: "#traffic-road1",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "bus1")
                .to("#bus1", { duration: .5, opacity: .25 }, "despawn-=6")
                .to("#bus1", { duration: 1, opacity: 0 }, "despawn-=1");
            return tl;
        }

        function semi2() {
            var tl = gsap.timeline();
            tl.to("#semi2", { duration: .5, opacity: .5 }, "spawn")
                .to("#semi2", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road1",
                        align: "#traffic-road1",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#semi2", { duration: .5, opacity: .25 }, "despawn-=6")
                .to("#semi2", { duration: 1, opacity: 0 }, "despawn-=1");
            return tl;
        }

        // Road 2
        function car1() {
            var tl = gsap.timeline();
            tl.to("#car1", { duration: .5, opacity: .5 }, "spawn")
                .to("#car1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road2",
                        align: "#traffic-road2",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#car1", { duration: .5, opacity: .25 }, "despawn-=6")
                .to("#car1", { duration: 1, opacity: 0 }, "despawn-=1");
            return tl;
        }

        // Road 3
        function car5() {
            var tl = gsap.timeline();
            tl.to("#car5", { duration: .5, opacity: .5 }, "spawn")
                .to("#car5", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road3",
                        align: "#traffic-road3",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#car5", { duration: .5, opacity: .25 }, "despawn-=6")
                .to("#car5", { duration: 1, opacity: 0 }, "despawn-=1");
            return tl;
        }

        function truck1() {
            var tl = gsap.timeline();
            tl.to("#truck1", { duration: .5, opacity: .5 }, "spawn")
                .to("#truck1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road3",
                        align: "#traffic-road3",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#truck1", { duration: .5, opacity: .25 }, "despawn-=6")
                .to("#truck1", { duration: 1, opacity: 0 }, "despawn-=1");
            return tl;
        }

        // Road 4
        function car2() {
            var tl = gsap.timeline();
            tl.to("#car2", { duration: .5, opacity: .5 }, "spawn")
                .to("#car2", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road4",
                        align: "#traffic-road4",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#car2", { duration: .5, opacity: .25 }, "despawn-=6")
                .to("#car2", { duration: 1, opacity: 0 }, "despawn-=1");
            return tl;
        }



        // Master Timeline For Repeating Traffic
        var tlMaster = gsap.timeline({ repeat: -1, repeatDelay: 1 });
        tlMaster
            .add(car1(), 'wave1')
            .add(semi2(), '<+=3')
            .add(bus1(), '<+=1.5')
            .add(car2(), 'wave1')
            .add(car5(), '<+=4')
            .add(truck1(), '<+=2');

        // Unload animation on page switch
        swup.hooks.before('content:replace', () => unload(tlMaster));

    }



    /**
     * Traffic Healthcare
     * 
     * 
     */
    if (document.querySelector('#healthcare')) {

        // Road 1
        function bus1() {
            var tl = gsap.timeline({id: 'bus1'});
           tl.to("#bus1", { duration: .5, opacity: .7, filter: "saturate(50%) blur(3px)"}, "spawn")
           .to("#bus1", {
               duration: 10,
               transformOrigin: "50% 50%",
               motionPath: {
                   path: "#traffic-road1",
                   align: "#traffic-road1",
                   alignOrigin: [0.5, 0.5],
                   start: 0,
                   end: 1
               },
               ease: "none"
           }, "spawn")
           .to("#bus1", { duration: .5, opacity: 1, filter: "brightness(50%) saturate(100%)" }, "despawn-=7")
           .to("#bus1", { duration: 1.05, filter: "brightness(100%)" }, "despawn-=4.86")
           .to("#bus1", { duration: 2, opacity: .5, filter: "saturate(20%) blur(2px)" }, "despawn-=2.4")
           .to("#bus1", { duration: 1, opacity: 0, ease: "power2.out", filter: "saturate(20%) blur(5px)" }, "despawn-=1");
            return tl;
        }

        function semi2() {
            var tl = gsap.timeline({id: 'semi2'});
            tl.to("#semi2", { duration: .5, opacity: .7, filter: "saturate(50%) blur(3px)"}, "spawn")
                .to("#semi2", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road1",
                        align: "#traffic-road1",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#semi2", { duration: .5, opacity: 1, filter: "brightness(50%) saturate(100%)" }, "despawn-=7")
                .to("#semi2", { duration: 1.05, filter: "brightness(100%)" }, "despawn-=4.86")
                .to("#semi2", { duration: 2, opacity: .5, filter: "saturate(20%) blur(2px)" }, "despawn-=2.4")
                .to("#semi2", { duration: 1, opacity: 0, ease: "power2.out", filter: "saturate(20%) blur(5px)" }, "despawn-=1");
            return tl;
        }

        // Road 2
        function car1() {
            var tl = gsap.timeline({id: 'car1'});
            tl.to("#car1", { duration: .5, opacity: .7, filter: "saturate(50%) blur(2px)" }, "spawn")
                .to("#car1", {
                    duration: 10,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road2",
                        align: "#traffic-road2",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#car1", { duration: .5, opacity: 1, filter: "brightness(50%) saturate(100%)" }, "despawn-=7")
                .to("#car1", { duration: .5, opacity: 1, filter: "brightness(100%)" }, "despawn-=4.6")
                .to("#car1", { duration: 2, opacity: .5, filter: "saturate(80%) blur(2px)" }, "despawn-=2.4")
                .to("#car1", { duration: .6, opacity: 0, ease: "power2.out", filter: "saturate(80%) blur(5px)" }, "despawn-=.6");
            return tl;
        }

        // Road 3
        function car5() {
            var tl = gsap.timeline({id: 'car5'});
            tl.to("#car5", { duration: .5, opacity: .7, filter: "saturate(20%) blur(2px)" }, "spawn")
                .to("#car5", {
                    duration: 6,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road3",
                        align: "#traffic-road3",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "power.Out"
                }, "spawn")
                .to("#car5", { duration: 2, opacity: 1, filter: "saturate(100%) blur(0px)" }, "despawn-=5.5")
                .to("#car5", { duration: .5, opacity: 0 }, "despawn-=.5");
            return tl;
        }

        function truck1() {
            var tl = gsap.timeline({id: 'truck1'});
            tl.to("#truck1", { duration: .5, opacity: .5, filter: "saturate(20%) blur(3px)" }, "spawn")
                .to("#truck1", {
                    duration: 6,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road3",
                        align: "#traffic-road3",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "none"
                }, "spawn")
                .to("#truck1", { duration: 2, opacity: 1, filter: "saturate(100%) blur(0px)" }, "despawn-=5.5")
                .to("#truck1", { duration: .5, opacity: 0 }, "despawn-=.5");
            return tl;
        }

        // Road 4
        function car2() {
            var tl = gsap.timeline({id: 'car2'});
            tl.to("#car2", { duration: .5, opacity: .7, filter: "saturate(20%) blur(2px)" }, "spawn")
                .to("#car2", {
                    duration: 6,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-road4",
                        align: "#traffic-road4",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: 1
                    },
                    ease: "power.Out"
                }, "spawn")
                .to("#car2", { duration: 2, opacity: 1, filter: "saturate(100%) blur(0px)" }, "despawn-=5.5")
                .to("#car2", { duration: .5, opacity: 0 }, "despawn-=.5");
            return tl;
        }



        // Master Timeline For Repeating Traffic
        var tlMaster = gsap.timeline({ repeat: -1, repeatDelay: 1 });
        tlMaster
            .add(car1(), 'wave1')
            .add(semi2(), '<+=3')
            .add(bus1(), '<+=1.5')
            .add(car2(), 'wave1')
            .add(car5(), '<+=4')
            .add(truck1(), '<+=2');
        
        //GSDevTools.create({animation: tlMaster})
        
        // Unload animation on page switch
        swup.hooks.before('content:replace', () => unload(tlMaster));
    }



    /**
     * Traffic Residential
     * 
     * 
     */
    if (document.querySelector('#residential')) {

        // Road 1
        function carTurn() {
            var tl = gsap.timeline();
            tl.to("#car-angle1", { duration: .5, opacity: 1 }, "spawn")
                .to("#car-angle1", {
                    duration: 5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: 0,
                        end: .28
                    },
                    ease: "none"
                }, "spawn")
                .to("#car-angle1", { duration: 0, opacity: 0 })
                .to("#car-angle2", { duration: 0, opacity: 1 })
                .to("#car-angle2", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .28,
                        end: .31
                    },
                    ease: "none"
                })
                .to("#car-angle2", { duration: 0, opacity: 0 })
                .to("#car-angle3", { duration: 0, opacity: 1 })
                .to("#car-angle3", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .31,
                        end: .33
                    },
                    ease: "none"
                })
                .to("#car-angle3", { duration: 0, opacity: 0 })
                .to("#car-angle4", { duration: 0, opacity: 1 })
                .to("#car-angle4", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .33,
                        end: .35
                    },
                    ease: "none"
                })
                .to("#car-angle4", { duration: 0, opacity: 0 })
                .to("#car-angle5", { duration: 0, opacity: 1 })
                .to("#car-angle5", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .35,
                        end: .37
                    },
                    ease: "none"
                })
                .to("#car-angle5", { duration: 0, opacity: 0 })
                .to("#car-angle6", { duration: 0, opacity: 1 })
                .to("#car-angle6", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .37,
                        end: .40
                    },
                    ease: "none"
                })
                .to("#car-angle6", { duration: 0, opacity: 0 })
                .to("#car-angle7", { duration: 0, opacity: 1 })
                .to("#car-angle7", {
                    duration: 5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .40,
                        end: .70
                    },
                    ease: "none"
                })
                .to("#car-angle7", { duration: 2, opacity: .6, filter: "grayscale(.8) blur(5px)" }, "<2.5")
                .to("#car-angle7", { duration: 0, opacity: 0 })
                .to("#car-angle6", { duration: 0, opacity: .6, filter: "grayscale(.8) blur(5px)" })
                .to("#car-angle6", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .70,
                        end: .72
                    },
                    ease: "none"
                })
                .to("#car-angle6", { duration: 0, opacity: 0 })
                .to("#car-angle5", { duration: 0, opacity: .6, filter: "grayscale(.8) blur(5px)" })
                .to("#car-angle5", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .72,
                        end: .74
                    },
                    ease: "none"
                })
                .to("#car-angle5", { duration: 0, opacity: 0 })
                .to("#car-angle4", { duration: 0, opacity: .6, filter: "grayscale(.8) blur(5px)" })
                .to("#car-angle4", {
                    duration: .75,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .74,
                        end: .76
                    },
                    ease: "none"
                })
                .to("#car-angle4", { duration: 0, opacity: 0 })
                .to("#car-angle3", { duration: 0, opacity: .6, filter: "grayscale(.8) blur(5px)" })
                .to("#car-angle3", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .76,
                        end: .78
                    },
                    ease: "none"
                })
                .to("#car-angle3", { duration: 0, opacity: 0 })
                .to("#car-angle2", { duration: 0, opacity: .6, filter: "grayscale(.8) blur(5px)" })
                .to("#car-angle2", {
                    duration: .5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .78,
                        end: .80
                    },
                    ease: "none"
                })
                .to("#car-angle2", { duration: 0, opacity: 0 })
                .to("#car-angle1", { duration: 0, opacity: .6, filter: "grayscale(.8) blur(5px)" })
                .to("#car-angle1", {
                    duration: 5,
                    transformOrigin: "50% 50%",
                    motionPath: {
                        path: "#traffic-path1",
                        align: "#traffic-path1",
                        alignOrigin: [0.5, 0.5],
                        start: .80,
                        end: 1
                    },
                    ease: "none"
                })
                .to("#car-angle1", { duration: .5, opacity: .75 }, "despawn-=3")
                .to("#car-angle1", { duration: 1, opacity: 0 }, "despawn-=1");

            return tl;
        }



        // Master Timeline For Repeating Traffic
        var tlTrafficRepeat = gsap.timeline({ repeat: -1, repeatDelay: 1 });
        tlTrafficRepeat
            .add(carTurn(), 'wave1');

        // Unload animation on page switch
        swup.hooks.before('content:replace', () => unload(tlTrafficRepeat));

         //GSDevTools.create({ id: "main", animation: tlTrafficRepeat });
    }
}



/**
 * SWUP Unload Scripts
 * 
 * Use this function to unload scripts.
 * 
 * Use: swup.hooks.before('content:replace', () => unload(<tlMasterName>));
 * 
 * @param {*} tlTrafficRepeat is the master timeline from specific sections.
 * @param {*} tlTrafficOnce is the secondary master that only plays once.
 */
function unload(tlTrafficRepeat, tlTrafficOnce) {
    console.log('Unload Stuff');

    // Kill specific GSAP animations
    if (tlTrafficRepeat) {
        tlTrafficRepeat.kill();
        tlTrafficRepeat = null;
    }

    if (tlTrafficOnce) {
        tlTrafficOnce.kill();
        tlTrafficOnce = null;
    }
}



/**
 * Welcome Pop Up
 * 
 * 
 */
// if (localStorage.getItem('UponorWelcome') != 'shown') {
//     $('.welcome').delay(2000).fadeIn();
//     localStorage.setItem('UponorWelcome', 'shown')
// }; $('.continue').on('click', function () {
//     $('.welcome').fadeOut();
// })

// cookie policy

    

  
 
 

 // Create cookie
 function setCookie(cname, cvalue, exdays) {
     //console.log('Setting cookie');
     const d = new Date();
     d.setTime(d.getTime() + (exdays*24*60*60*1000));
     let expires = "expires="+ d.toUTCString();
     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
 }
 
 // Read cookie
 function getCookie(cname) {
     //console.log('Checking cookie');
     let name = cname + "=";
     let decodedCookie = decodeURIComponent(document.cookie);
     let ca = decodedCookie.split(';');
     for(let i = 0; i <ca.length; i++) {
         let c = ca[i];
         while (c.charAt(0) == ' ') {
             c = c.substring(1);
         }
         if (c.indexOf(name) == 0) {
             return c.substring(name.length, c.length);
         }
     }
     return "";
 }
 
 let cookieCheck = getCookie("_u_welcome");
       if (cookieCheck === "") {
           //console.log('Uponor Welcome Cookie not set');
       $('.welcome').delay(2000).fadeIn();
       setCookie('_u_welcome', 1, 30);
       
   }
 
 $('.continue').on('click', function () {
   $('.welcome').fadeOut();
   })


/**
 * Swup Hooks
 * 
 * 
 */
swup.hooks.on('content:replace', loadJS);
